<template>
  <div class="">
    <search-bar />
  </div>
</template>

<script>
import SearchBar from '@/views/suppliers/AddEstablishmentSupplierSearchBar.vue'

export default {
  components: {
    SearchBar,
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>
