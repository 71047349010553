<template>
  <b-container>
    <!-- Products search -->
    <b-card class="mb-2">
      <h5 class="display-4">
        Agregar proveedores
      </h5>
      <p>Buscar proveedor por nombre</p>
      <b-input-group>
        <b-form-input
          v-model="searchQuery"
          variant="outline-primary"
          placeholder="Nombre"
          @input="lookupStoreProducts"
        />
        <b-input-group-append v-if="searchQuery">
          <b-button
            variant="outline-warning"
            @click="clearSearchbarAndResetSearch()"
          >
            Borrar
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </b-card>
    <div
      v-if="searchQuery"
      class="checkout-items list-view"
    >
      <!-- SEARCH RESULTS -->
      <b-card
        v-for="supplier in suppliers"
        :key="supplier.id"
        class="grid-list mb-1"
        no-body
      >

        <!-- Product Details: Card Body -->
        <b-card-body>
          <b-row>
            <b-col class="item-name">
              <h6 class="mb-0">
                <b-link class="text-body">
                  {{ supplier.name }}
                </b-link>
              </h6>
            </b-col>
          </b-row>
        </b-card-body>

        <!-- Product Options/Actions -->
        <div class="item-options">
          <b-button
            variant="success"
            class="mt-1 remove-wishlist"
            :to="{ name: 'add-supplier-to-establishment' , params: { establishment_id: $route.params.establishment_id, supplier_id: supplier.id }}"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
            />
            <span>Agregar</span>
          </b-button>
        </div>
      </b-card>

      <!-- PAGINATION -->
      <b-pagination
        v-if="pagination.total_objects > pagination.per_page"
        v-model="pagination.page"
        :total-rows="pagination.total_objects"
        :per-page="pagination.per_page"
        first-number
        last-number
        class="mb-0 mt-1 mt-sm-0"
        prev-class="prev-item"
        next-class="next-item"
        @change="(value)=>{handlePagination({ page: value, per_page: pagination.per_page })}"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18"
          />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
        </template>
      </b-pagination>
      <!-- ADD NEW PRODUCT -->
      <b-card
        class="mt-1 mb-1"
        no-body
      >
        <b-card-body>
          <h3 class="display-5">
            ¿No encontraste el proveedor deseado? 😲
          </h3>
          <p>Da click en el botón de abajo para registrar un nuevo proveedor</p>
          <b-button
            variant="primary"
            :to="{ name: 'add-new-supplier-to-establishment', params: { establishment_id: $route.params.establishment_id }}"
          >
            <span class="text-nowrap">Agregar proveedor</span>
          </b-button>
        </b-card-body>
      </b-card>
    </div>
  </b-container>

</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BLink,
  BButton,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BContainer,
  BPagination,
} from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'
import { debounce } from 'lodash'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BContainer,
    BCardBody,
    BLink,
    BButton,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BPagination,
  },
  data() {
    return {
      searchQuery: '',
      isAddNewCategorySidebarActive: false,
      setSuppliers(suppliers) {
      },
    }
  },
  computed: {
    ...mapGetters('suppliers', [
      'suppliers',
      'pagination',
    ]),
  },
  methods: {
    ...mapActions('suppliers', ['fetchSuppliers']),
    lookupStoreProducts: debounce(function searchQuery(query) {
      if (query != null && query !== '') {
        this.fetchSuppliers({
          by_name: query,
          by_active_status: 1,
        })
      }
    }, 250),
    handlePagination({ page, per_page }) {
      this.fetchSuppliers({
        by_name: this.searchQuery,
        meta: {
          pagination: {
            page,
            per_page,
          },
        },
      })
    },
    clearSearchbarAndResetSearch() {
      this.searchQuery = ''
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>

.grid-list {
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr auto;

  .card-body {
    padding: 1.5rem 1rem;
    border-right: 1px solid #3b4253;
    display: flex;
    flex-direction: column;
  }

  .item-options {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
</style>
